import React from "react"
import style from './Button.module.css'
type ButtonType = {
    title: string,
    disabled?: boolean,
    theme?: string,
    size?: string,
    py?: number,
    onClick: (e?: any) => void
}

function Button({title, size = 'lg', theme = 'success', onClick, py, disabled = false}: ButtonType) {
    return <button
        disabled={disabled}
        onClick={onClick}
        className={`btn btn-${size} btn-${theme} shadow w-100 py-${py} mb-2 font-weight-bold`}
    >
        <b>{title.toUpperCase()} {`${disabled ? '...' : ''}`}</b>
    </button>
}
export default Button
