import React, { useState, useRef  } from "react"
import styles from './InputText.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import colors from "../../var/colors";

export default function ImagePicker({id, title, fileSelected}: {id: string, title: string, fileSelected: Function}) {

    const [text, setValue] = useState<string>('')
    const [image, setImage] = useState<string|undefined>(undefined)
    const [selectedFile, setSelectedFile] = useState<any>(null)
    const fileInputRef = useRef<any>(null)

    const handleSelectedFile = (event: any) => {

        event.preventDefault();

        const file = event.target.files[0];

        setSelectedFile(file);

        if (file) {
            const imageUrl = URL.createObjectURL(file);
            fileSelected(id, file)
            setImage(imageUrl)
        } else {
            setImage(undefined);
        }
    };

    const cancelImage = (event: any) => {
        event.stopPropagation()
        setImage(undefined)
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <section>
            <div className={styles.label}>
                {title}
            </div>

            <div className={styles.upload}>

                { image && image.length > 0 && <div className='position-relative'>
                    <button  onClick={cancelImage} className={styles.dropImage}>
                        {/*@ts-ignore*/}
                        <FontAwesomeIcon className='fa-2x text-danger' icon={faTrash} />
                    </button>
                </div>}

                <label className='d-flex align-items-center justify-content-center w-100 h-100 text-center'>
                    <input
                        type='file'
                        id={id}
                        className={styles.file}
                        onChange={handleSelectedFile}
                        accept='image/*'
                        ref={fileInputRef}
                    />

                    {/*@ts-ignore*/}
                    { image === undefined && <FontAwesomeIcon style={{color: colors.primary}} className='fa-2x' icon={faPlus} />}

                    {selectedFile && image && (
                        <div className={styles.image} style={{backgroundImage: `url(${image})`}}></div>
                    )}
                </label>
            </div>
        </section>
    )
}
