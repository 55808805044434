import * as React from "react"
import {AppLayout} from "../layouts"
import {Banner, FormControls} from "../components";
import {useNavigate} from "react-router-dom";

export default function AppScreen() {
    const navigate = useNavigate();
    React.useEffect(() => {

    }, [])


    return (
        <AppLayout>
            <React.Fragment>
                <Banner />
                <FormControls />
            </React.Fragment>
        </AppLayout>
    );
}
