import {Outlet} from "react-router-dom"
import * as React from "react"
import {Header, } from "./index"
import {useApp} from "../contexts/appContext"

type AppLayoutType = {
    children: React.ReactNode,
    goBack?: string
}

export default function AppLayout({goBack, children}: AppLayoutType) {

    const {labels} = useApp()

    return (
        <React.Fragment>
            <Header goBack={goBack} />
            <div>
                {children}
                <Outlet />
            </div>
        </React.Fragment>
    );
}
