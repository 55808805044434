import * as React from "react"
import {Language} from "../components"
import {Link} from "react-router-dom";
export default function Header({goBack}: {goBack?: string}) {

    return (
        <header>
            <nav className="navbar bg-white navbar-expand-lg fixed-top  App-header">
                <div className='d-flex justify-content-between align-items-center w-100 px-4'>

                    <div className="w-100">
                        <Link to='/'>
                            <img style={{width: 40}} src="/logo.png" alt="Logo IGLSI" />
                        </Link>
                    </div>

                    <div className='d-flex justify-content-end'>
                        <Language />
                    </div>
                </div>
            </nav>
        </header>
    )
}
