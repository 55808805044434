export default function Banner() {
    return (
        <div className="text-center mt-4 py-5 d-flex justify-content-center align-items-center bg-banner" style={{height: 350,}}>
            <div className="py-4 text-white">
                <h6 className='text-uppercase'>
                    Transporteurs ?
                </h6>

                <h1 className='font-weight-bold'>Devenez partenaire de IGLSI <br /> et transportez les biens de nos clients</h1>
                <p className='font-weight-bold'>Inscrivez-vous pour devenir partenaire IGLSI</p>
            </div>
        </div>
    )
}
