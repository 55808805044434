import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React from "react";

export default function Loader({show = true}: {show?: boolean}) {

    if (! show) {
        return <span />;
    }

    return <p className='d-flex justify-content-center'>
        {/*@ts-ignore*/}
        <FontAwesomeIcon className={`fa-spin fa-2x`} icon={faSpinner} />
        {/*<p>
            <i>Traitement de votre demande ...</i>
        </p>*/}
    </p>
}
