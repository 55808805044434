import * as React from 'react'
import {Routes, Route,} from 'react-router-dom'
import AppProvider from './contexts/appContext'
import {AppScreen, LandingScreen, AlertScreen} from './screens'

export default function App() {

  return (
      <AppProvider>
        <Routes>
            <Route path="/" element={<LandingScreen />} />
            <Route path="/home" element={<AppScreen />} />
            <Route path="/sent" element={<AlertScreen />} />
        </Routes>
      </AppProvider>
  );
}

