import React, { useState } from 'react';
import {Button, Input, Loader} from "./index";
import {SelectCustom} from "./Forms/index.tsx";
import ImagePicker from "./Forms/ImagePicker";

const _types = [
    { value: 'Fourgon', label: 'Fourgon' },
    { value: 'Camion Benne', label: 'Camion Benne' },
    { value: 'Citerne', label: 'Citerne' },
    { value: 'Dépaneuse', label: 'Dépaneuse' },
]

const _sizes = [
    { value: '3.5t', label: '3.5t' },
    { value: '5t', label: '5t' },
    { value: '10t', label: '10t' },
    { value: '15t', label: '15t' },
]

let image = '/banner.avif'

export default function FormControls({options}: any) {

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  return (
      <div className="p-4 position-relative bg-image" style={{backgroundImage: `url(${image})`}}>

          <section className="borde p-4 rounded position-relative bg-white" style={{top: -100}}>

              <h2 className="text-center h5 text-uppercase text-banner">Devenez Transporteur IGLSI</h2>

              <div className='row'>
                  <div className="col-6 col-md-6">
                      <Input
                          label="Nom"
                          uid="last_name"
                          placeholder=""
                          required={true}
                          onChanged={() => {}}
                      />
                  </div>
                  <div className="col-6 col-md-6">
                      <Input
                          label="Prénom"
                          uid="first_name"
                          placeholder=""
                          required={true}
                          onChanged={() => {}}
                      />
                  </div>
              </div>

              <div className='row'>
                  <div className="col-12 col-md-6">
                      <Input
                          label="Email"
                          uid="email"
                          placeholder=""
                          required={true}
                          onChanged={() => {}}
                      />
                  </div>
                  <div className="col-12 col-md-6">
                      <Input
                          label="Téléphone"
                          uid="phone"
                          placeholder=""
                          required={true}
                          onChanged={() => {}}
                      />
                  </div>
              </div>

              <SelectCustom
                  label="Type de camion"
                  selected='foo'
                  uid='type_id'
                  onChanged={() => {}}
                  options={_types}
              />

              <SelectCustom
                  label="Capacité du camion"
                  selected='foo'
                  uid='size_id'
                  onChanged={() => {}}
                  options={_sizes}
              />

              <Input
                  label="Immatriculation"
                  uid="registrer_number"
                  placeholder=""
                  required={true}
                  onChanged={() => {}}
              />

              <Input
                  label="Marque"
                  uid="brand"
                  placeholder=""
                  required={true}
                  onChanged={() => {}}
              />

              <div className="d-flex justify-content-center text-center">
                  <ImagePicker id="image" title="Ajouter une photo" fileSelected={(id: any, file: any) => {

                  }} />
              </div>

              <br/>

              <Button disabled={true} title="Soumettre" onClick={() => {}} />

              <div className="text-center">
                  <Loader />
              </div>

              <div className='alert alert-danger'>
                  Cette application en cours de développement et n'est donc pas encore fonctionnelle.
              </div>

          </section>
      </div>
  );
}
